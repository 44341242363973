const handleDomainSearchForm = () => {
    const domainSearchForms = document.querySelectorAll('.domainSearchInput');
    if (!domainSearchForms.length) return;

    domainSearchForms.forEach((form) => {
        const dropdown = form.querySelector('.domain-search-dropdown-menu');
        const dropdownMenuTLD = form.querySelector('#dropdownMenuTLD');
        const selectDisplay = form.querySelector('.domain-search-dropdown .select span');

        // for domain search pattern
        const domainBlockPattern = form.querySelector(".domain-search-wrapper-v2");


        if (dropdown) {
            const tldItems = dropdown.querySelectorAll('li');
            tldItems.forEach(li => {
                li.addEventListener('click', () => {
                    const tld = li.textContent.trim();
                    dropdownMenuTLD.value = tld;
                    selectDisplay.textContent = tld;
                });
            });
        }

        if (domainBlockPattern) {
            collapseDomainContainer();
        }

        form.addEventListener('submit', handleSubmit);
    });
}

function adjustDropdownPosition(dropdown) {
    dropdown.style.display = 'block';
    const rect = dropdown.getBoundingClientRect();
    dropdown.style.display = '';
    const spaceBelow = window.innerHeight - rect.bottom;
    const spaceAbove = rect.top;
    const dropdownHeight = 300

    dropdown.classList.remove('show-above', 'show-below');

    if (spaceAbove > dropdownHeight && spaceBelow < dropdownHeight) {
        dropdown.classList.add('show-above');
    } else {
        dropdown.classList.add('show-below');
    }
}

function collapseDomainContainer() {
    let lastScrollTop = 0;
    const threshold = 5;
    const searchContainer = document.querySelector(".domain-search-container");

    window.addEventListener("scroll", function () {
        const scrollTop = window.scrollY || document.documentElement.scrollTop;

        if (scrollTop > threshold && scrollTop > lastScrollTop) {
            searchContainer.classList.add("collapsed");
        } else if (scrollTop < lastScrollTop && scrollTop < threshold) {
            searchContainer.classList.remove("collapsed");
        }

        lastScrollTop = scrollTop;
    });
}


function handleSubmit(e) {
    e.preventDefault();

    const baseUrl = 'https://www.registeredagentsinc.com/domain-search-page/';
    const domainInput = cleanDomainName(this.querySelector('.txtDomain').value);

    const tldDropdown = this.querySelector('.domain-search-dropdown-menu');
    let selectedTLD = getSelectedTLD(this);

    if (domainInput && tldDropdown) {
        this.action = `${baseUrl}${domainInput}${selectedTLD}`;
    }

    if (domainInput) {
        this.action = `${baseUrl}${domainInput}.com`;
    }

    this.submit();
}


function getSelectedTLD(form) {
    return form.querySelector('#dropdownMenuTLD')?.value || '.com';
}

function cleanDomainName(domainName) {
    return domainName.trim().replace(/\s+/g, '');
}

export default handleDomainSearchForm;
