export function handleCartProducts(products, PREFIX, SAVED_FORM_KEY) {
    const container = document.getElementById('cart-items');
    const template = document.getElementById('cart-item-template');
    container.innerHTML = '';
    let subtotal = 0;

    // Group products by name
    const grouped = {};

    // Combine duplicated products for multi-state products (i.e - DBA, Registered Agent, etc)
    products.forEach(product => {
        const { name, price } = product;
        if (name === 'Renewal Service') return;
        if (!grouped[name]) {
            grouped[name] = {
                name,
                price: 0,
                subItems: [],
            };
        }
        grouped[name].price += parseFloat(price);
        grouped[name].subItems.push(...generateSubLineItems(product));
    });

    // Render each grouped product
    Object.values(grouped).forEach(group => {
        const clone = template.content.cloneNode(true);

        clone.querySelector('.product-name').textContent = group.name;


        // Check billing type
        const productMatch = products.find(p => p.name === group.name);
        let billingSuffix = '';

        if (productMatch?.data?.billingType === 'monthly') {
            billingSuffix = '/Month';
        } else if (productMatch?.data?.billingType === 'yearly') {
            billingSuffix = '/Year';
        }

        clone.querySelector('.current-price').textContent = `$${group.price.toFixed(2)}${billingSuffix}`;

        const subDetails = clone.querySelector('.cart-sub-details');

        const uniqueItems = [...new Set(group.subItems)];

        const stateItems = uniqueItems.filter(item => /^[A-Z]{2}$/.test(item));
        const otherItems = uniqueItems.filter(item => !/^[A-Z]{2}$/.test(item));

        // handle multi-state container
        if (stateItems.length > 0) {
            const stateLine = document.createElement('div');
            stateLine.classList.add('cart-state-list');

            stateItems.forEach(state => {
                const span = document.createElement('span');
                span.textContent = state;
                stateLine.appendChild(span);
            });

            subDetails.appendChild(stateLine);
        }

        otherItems.forEach(item => {
            const p = document.createElement('p');
            p.textContent = item;
            subDetails.appendChild(p);
        });

        container.appendChild(clone);
        subtotal += group.price;
    });


    function generateSubLineItems(product) {
        const lines = [];

        const productsWithState = [
            "Registered Agent Service",
            "Change Registered Agent",
            "DBA (Trade Name)"
        ];

        if (productsWithState.includes(product.name) && product.state) {
            lines.push(product.state);
        }

        // Add domain name for Domain Name Signup product
        if (product.name === "Domain Name Signup") {
            const domainName = getEnteredDomainIfSignup(PREFIX, SAVED_FORM_KEY);
            if (domainName) lines.push(domainName);
        }
        return lines;
    }

    function getEnteredDomainIfSignup(PREFIX, SAVED_FORM_KEY) {
        const lastVisitedUrl = localStorage.getItem(PREFIX + SAVED_FORM_KEY);
        if (!lastVisitedUrl) return null;

        try {
            const slug = new URL(lastVisitedUrl).pathname;
            if (slug !== "/domain-signup/") return null;

            const domainStoreRaw = localStorage.getItem("vuex-domains");
            if (!domainStoreRaw) return null;

            const domainStore = JSON.parse(domainStoreRaw);
            return domainStore.domains.enteredDomainName

        } catch (error) {
            console.error("Error accessing domain data:", error);
            return null;
        }
    }

    document.getElementById('cart-subtotal').textContent = `$${subtotal.toFixed(2)}`;
}
