const handleDropdownToggle = () => {
    const dropdownHeadings = document.querySelectorAll('.state-dropdown-heading');
    const dropdownMenus = document.querySelectorAll('.state-dropdown-menu');
    if (!dropdownHeadings.length && !dropdownMenus) return; // Early return if no elements exist

    dropdownHeadings.forEach((dropdownHeading, index) => {
        dropdownHeading.addEventListener('click', function () {
            dropdownMenus[index].classList.toggle('active');
        });
    });
};

export default handleDropdownToggle;
