import { handleCartProducts } from './cartHelper.js';


export default function() {
    const PREFIX = '_RAINC_';
    const cartButtonLink = $('.shopping-cart-item');
    const SAVED_FORM_KEY = 'saved_form_url';

    const vuexPrefixes = {
        "/place-an-order/": "vuex-RegisteredAgentApp.js",
        "/form-an-entity/": "vuex-FormABusinessApp.js",
        "/domain-signup/": "vuex-DomainSignupApp.js"
    };

    const formDisplayNames = {
        "/place-an-order/": "Registered Agent Signup",
        "/form-an-entity/": "Business Formation Signup",
        "/domain-signup/": "Domain Signup"
    };

    // Save the form's URL to local storage.
    const saveFormURL = () => {
        const onShoppingCartForm = $('.shopping-cart-signup-form').length;

        if (onShoppingCartForm) {
            setLocalStorageItem('saved_form_url', window.location.href);
        }
    };

    // Get a value from local storage by it's key.
    const getLocalStorageItem = (key, prefix = PREFIX) => {

        if (typeof Storage !== 'undefined') {
            return localStorage.getItem(prefix + key);
        }

        console.warn('No Web Storage support');
    };

    // Set a value to local storage.
    const setLocalStorageItem = (key, val, prefix = PREFIX) => {
        if (typeof Storage === 'undefined') {
            console.warn('No Web Storage support');
        } else {
            localStorage.setItem(prefix + key, val);
        }
    };

    // Dynamically show cart icon
    const maybeShowShoppingCart = () => {
        const currentUrl = window.location.href;
        const formUrl = getLocalStorageItem('saved_form_url');
        const onShoppingCartForm = $('.shopping-cart-signup-form').length;

        if (formUrl !== null) {
            cartButtonLink.attr('href', formUrl);
            setLocalStorageItem('previous_saved_form_url', formUrl);

            if (currentUrl !== formUrl && !onShoppingCartForm) {
                const iconContainer = $('.shopping-cart-icon')
                iconContainer.addClass('show-cart');
            }
        }
    };

    // Function to get Vuex cart data based on the last visited form URL
    const getVuexCartData = () => {
        const lastVisitedUrl = localStorage.getItem(PREFIX + SAVED_FORM_KEY);

        if (!lastVisitedUrl) {
            console.warn("No saved form URL found.");
            return null;
        }

        // get pathname from full saved URL
        const urlObj = new URL(lastVisitedUrl);
        const slug = urlObj.pathname;

        // Map to Vuex prefix
        const vuexPrefix = vuexPrefixes[slug];
        if (!vuexPrefix) {
            console.warn(`No Vuex prefix found for slug: ${slug}`);
            return null;
        }

        // Find the actual Vuex key in localStorage
        const vuexKey = Object.keys(localStorage).find(key =>
            key.startsWith(vuexPrefix)
        );

        if (!vuexKey) {
            console.warn(`No cart key found matching prefix: ${vuexPrefix}`);
            return null;
        }

        const vuexData = localStorage.getItem(vuexKey);

        if (!vuexData) {
            console.warn(`No cart data found for key: ${vuexKey}`);
            return null;
        }

        try {
            return JSON.parse(vuexData);
        } catch (error) {
            console.warn("Error parsing cart data:", error);
            return null;
        }
    };

    // Cart icon click behavior
    cartButtonLink.on('click', (event) => {
        event.preventDefault();

        const drawer = document.getElementById('cart-drawer');
        const overlay = document.getElementById('cart-overlay');

        const cartData = getVuexCartData();

        if (!cartData) {
            console.warn("No Cart Data found.");
            return;
        }

        // Toggle behavior
        if (drawer.classList.contains('show')) {
            drawer.classList.remove('show');
            overlay?.classList.remove('show');
            setTimeout(() => {
                drawer.classList.add('hidden');
                overlay?.classList.add('hidden');
            }, 300);
            return;
        }

        // Populate cart and show drawer
        const formCartProducts = cartData.cart.cartProducts;

        // Handle cart products in local storage (from cartHelper.js)
        handleCartProducts(formCartProducts, PREFIX, SAVED_FORM_KEY);

        drawer.classList.remove('hidden');
        overlay?.classList.remove('hidden');
        setTimeout(() => {
            drawer.classList.add('show');
            overlay?.classList.add('show');
        }, 10);
    });

    // Form title
    const setCartFormName = (keyPrefix = PREFIX, formKey = SAVED_FORM_KEY) => {
        const lastVisitedUrl = localStorage.getItem(PREFIX + SAVED_FORM_KEY);

        if (lastVisitedUrl) {
            const urlObj = new URL(lastVisitedUrl);
            const slug = urlObj.pathname;

            const displayName = formDisplayNames[slug];
            const formLink = document.getElementById('cart-form-name');

            if (formLink && displayName) {
                formLink.textContent = displayName;
                formLink.setAttribute('href', lastVisitedUrl);
            }
        }

    };

    // Close cart drawer when clicking page
    const closeCartDrawer = () => {
        const overlay = document.getElementById('cart-overlay');
        const drawer = document.getElementById('cart-drawer');
        drawer?.classList.remove('show');
        overlay?.classList.remove('show');

        setTimeout(() => {
            drawer?.classList.add('hidden');
            overlay?.classList.add('hidden');
        }, 300);
    };

    // Other methods to close cart
    document.querySelector('.close-cart')?.addEventListener('click', closeCartDrawer);
    document.querySelector('.continue-btn')?.addEventListener('click', closeCartDrawer);
    document.getElementById('cart-overlay')?.addEventListener('click', (e) => {
        const drawer = document.getElementById('cart-drawer');
        if (!drawer.contains(e.target)) {
            closeCartDrawer();
        }
    });

    // Direct user to associated form
    document.querySelector('.checkout-btn')?.addEventListener('click', () => {
        const savedUrl = localStorage.getItem(PREFIX + SAVED_FORM_KEY);

        if (savedUrl) {
            window.location.href = savedUrl;
        } else {
            console.warn("No saved form URL found. Redirect canceled.");
        }
    });

    // Handle where CTA banner is present on top of page
    if (document.querySelector('.cta-banner-wrapper')) {
        console.log("hello ")
        const main = document.querySelector('main');
        if (main) {
            main.classList.add('has-cta-banner');
        }
    }

    // Accessibility logic
    // TODO: add logic for keyboard use

    saveFormURL()
    setCartFormName()
    maybeShowShoppingCart()
}


