import maps from '../components/us-map.js';

export default function() {

    // Slick Library (used in testimonial block, potential to be used in other blocks with slider functionality)
    // https://kenwheeler.github.io/slick/
    // TODO: Possible removal. Research where being used. -Luna
    // if ($("body").is('.page-template-block-based-landing-page, .block_listing-template-default')) {
    //
    //     // find the card containers and add the Slick library to it
    //     $("div.carousel-wrapper > .carousel-container").slick({
    //         dots: false,
    //         centerMode: true,
    //         infinite: true,
    //         speed: 1000,
    //         slidesToShow: 1,
    //         slidesToScroll: 1,
    //         prevArrow: '<button type="button" className="slick-prev"></button>',
    //         nextArrow: '<button type="button" className="slick-next"></button>',
    //         responsive: [
    //             {
    //                 breakpoint: 1800,
    //                 settings: {
    //                     slidesToShow: 1,
    //                     slidesToScroll: 1,
    //                     infinite: true,
    //                     dots: false,
    //                     centerMode: false,
    //                 }
    //             },
    //
    //             {
    //                 breakpoint: 1500,
    //                 settings: {
    //                     slidesToShow: 1,
    //                     slidesToScroll: 1,
    //                     infinite: true,
    //                     dots: false,
    //                     centerMode: false,
    //                 }
    //             },
    //             {
    //                 breakpoint: 876,
    //                 settings: {
    //                     slidesToShow: 1,
    //                     slidesToScroll: 1,
    //                     infinite: true,
    //                     dots: false,
    //                     centerMode: false,
    //                 }
    //             },
    //
    //             {
    //                 breakpoint: 400,
    //                 settings: {
    //                     slidesToShow: 1,
    //                     slidesToScroll: 1,
    //                     centerMode: false,
    //                 }
    //             }
    //             // You can unslick at a given breakpoint now by adding:
    //             // settings: "unslick"
    //             // instead of a settings object
    //         ]
    //     });
    // }

    // TABLES BLOCK SCRIPTS
    // wraps scrollable tables on medium and smaller viewports
    function scrollWrap() {
        $('table').wrap('<div class="scroll-wrapper"></div>');
        $('.scroll-wrapper').before( '<div class="scroll-header">Swipe to view more content</div>' );
    }
    scrollWrap();
    // show or hide table scroll header based on if overflowing its parent
    function handleScrollResize() {
        $('table').each(function(index) {
            var table = $(this);
            var tableWidth = table.outerWidth();
            var parentWidth = table.parent().outerWidth();
            var scrollHeader = table.parent().prevAll('.scroll-header:first');

            if (tableWidth > parentWidth) {
                // element has overflow
                scrollHeader.show();
            } else {
                // element doesn't overflow
                scrollHeader.hide();
            }
        })
    }

    // Accordion javascript
    var isAnimating = false;
    $('.single-accord .title').on('click', function (e) {
        //limiting scope of interaction to just one carousel when more than one carousels are on the page.
        var $curAccord = $(this).parent().parent();
        if (!isAnimating) {
            isAnimating = true;
            if ($(this).next().hasClass('current')) {
                $curAccord.find('.single-accord .title .icon').removeClass('rotate-icon');
                $curAccord.find('.single-accord .accordion').removeClass('change-color');
                $(this).next().removeClass('current').slideUp(function () {
                    isAnimating = false;
                });
            } else {
                $curAccord.find('.single-accord .display-status').slideUp().removeClass('current');
                $curAccord.find('.single-accord .title .icon').removeClass('rotate-icon');
                $curAccord.find('.single-accord .accordion').removeClass('change-color');
                $(this).next().addClass('current').slideToggle(function () {
                    isAnimating = false;
                });
                $(this).find('span').addClass('rotate-icon');
                $(this).find('.accordion').addClass('change-color');
            }
        }
    });
    // End Accordion javascript

    // Domain search dropdown
    $('.domain-search-dropdown').click(function () {
        $(this).attr('tabindex', 1).focus();
        $(this).toggleClass('active');
        $(this).find('.domain-search-dropdown-menu').slideToggle(300);
    });
    $('.domain-search-dropdown').focusout(function () {
        $(this).removeClass('active');
        $(this).find('.domain-search-dropdown-menu').slideUp(300);
    });
    $('.domain-search-dropdown .domain-search-dropdown-menu li').click(function () {
        $(this).parents('.domain-search-dropdown').find('span').text($(this).text());
        $(this).parents('.domain-search-dropdown').find('input').attr('value', $(this).attr('value'));
        $(this).parents('.domain-search-input-container').find('input.hidden-tld').attr('value', $(this).attr('value'));
    });
    // End Domain search dropdown


    // Help text logic
    $('.my-help-text-trig').on('click', function () {
        const blockId = $(this).attr('id');
        const modal = $('#' + blockId + '.modal-helptext');
        $('.modal-helptext').not(modal).hide();
        modal.show();
        console.log()
    });

    $('.modal-close-button').on('click', function () {
        $('.modal-helptext').hide(); // Hide all modals
    });

    // TODO: Refactor to not fire on all the document models (aka every page). Possibly Change class names to be more specific to the block it is used on. -LB
    $(document).on('click', function (event) {
        if (!$(event.target).closest('.modal-container').length && !$(event.target).is('.my-help-text-trig')) {
            $('.modal-helptext').hide();
        }
    });
    // End help text logic

    // TODO: Refactor to not reload the homepage every time window is resized. -LB
    $(window).on("resize", function () {
        handleScrollResize();
    });

    $(document).ready( function () {
        handleScrollResize();

        // For the Maps block
        if( $('#map').length ) {
            // Initialize the maps block
            maps.init();

            // Placeholder image rendered only in Editor, but container on client side is still present, so we need to remove it:
            $('#maps-placeholder-image').remove();
        }
    });
}