const handleStateNav = () => {
    const accordionButton = document.querySelector('.biz-reporting-accordion-button');
    const accordionContent = document.querySelector('.biz-reporting-accordion-content');
    const accordionContainer = document.querySelector('.biz-reporting-accordion');

    if (!accordionButton || !accordionContent || !accordionContainer) {
        return;
    }
    accordionContainer.classList.add('closed');

    accordionButton.addEventListener('click', () => {
        const isVisible = accordionContent.style.display === 'block';
        accordionContent.style.display = isVisible ? 'none' : 'block';

        if (isVisible) {
            accordionContainer.classList.add('closed');
            accordionContainer.classList.remove('open');
        } else {
            accordionContainer.classList.add('open');
            accordionContainer.classList.remove('closed');
        }
    });

};

export default handleStateNav;
