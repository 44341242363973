export default function () {

    // all JS should be dependent on these
    const body = document.querySelector('body');
    const nav = document.querySelector('.nav');
    const navMenu = nav.querySelector('.navigation-left')
    const mobileNavTrigger = document.querySelector('.mobile-nav-trigger');
    //search logic
    let isSearchbarShowing = false;
    const searchTriggers = document.querySelectorAll('.search-btn-trigger');
    const searchContainer = document.querySelector('.search-container');

    // TODO: make this more utility-based, functional, or a class. It's too far-reaching.
    if (body && nav && navMenu && mobileNavTrigger) {
        const isDesktopWidth = window.matchMedia('(min-width: 992px)');

        // toggles mobile hamburger menu
        function toggleMobileMenu() {
            body.classList.toggle('disable-scroll');
            mobileNavTrigger.classList.toggle('active');
            nav.classList.toggle('mobile-menu-open');

            // if the classlist contains active, turn off search

            if (mobileNavTrigger.classList.contains('active')){
                isSearchbarShowing = false;
                toggleSearchInput(isSearchbarShowing, searchContainer)
            }
        }

        // opens submenu
        function openSubmenu(submenu, submenuToggle) {
            submenu.classList.add('sub-menu-open');
            submenuToggle.classList.add('flip-chevron');

            // and toggle the aria stuff
            submenu.setAttribute('aria-hidden', 'false');
            submenuToggle.setAttribute('aria-expanded', 'true');
        }

        // closes submenu
        function closeSubmenu(submenu, submenuToggle) {
            submenu.classList.remove('sub-menu-open');
            submenuToggle.classList.remove('flip-chevron');

            // and toggle the aria stuff
            submenu.setAttribute('aria-hidden', 'true');
            submenuToggle.setAttribute('aria-expanded', 'false');
        }

        // mobile nav controller
        mobileNavTrigger.addEventListener('click', event => {
            toggleMobileMenu();
        });

        // allows escape key to close mobile nav
        window.addEventListener('keyup', event => {
            if (event.key === 'Escape') {
                // if the mobile menu is open
                if (nav.classList.contains('mobile-menu-open')) {
                    // close any open submenu
                    const visibleSubmenu = document.querySelector('.sub-menu-open');
                    if (visibleSubmenu) {
                        const visibleSubmenuToggle = visibleSubmenu.previousSibling;
                        closeSubmenu(visibleSubmenu, visibleSubmenuToggle);
                    }
                    //and close the mobile menu
                    toggleMobileMenu();
                }
            }
        });

        // closes mobile menu and any open submenu
        function closeMobileMenu (event) {
            if (event.matches || override) {
                // if the mobile menu is open
                if (nav.classList.contains('mobile-menu-open')) {
                    // close any open submenu
                    const visibleSubmenu = document.querySelector('.sub-menu-open');
                    if (visibleSubmenu) {
                        const visibleSubmenuToggle = visibleSubmenu.previousSibling;
                        closeSubmenu(visibleSubmenu, visibleSubmenuToggle);
                    }
                    // and close the mobile menu
                    toggleMobileMenu();
                }
            }
        }

        // handles closing mobile menu and any open submenu when screen is resized from mobile to desktop
        isDesktopWidth.addEventListener('change', closeMobileMenu);

        // handles submenu open/close on click (mobile)
        function handleSubmenuToggleClicks(event){
            const clickTarget = event.target;
            const closestSubmenu = event.target.nextSibling;

            // if the submenu toggle is clicked
            if (!closestSubmenu.classList.contains('sub-menu-open')) {
                const visibleSubmenu = document.querySelector('.sub-menu-open');
                // if there is a submenu already open, close it
                if (visibleSubmenu) {
                    const visibleSubmenuToggle = visibleSubmenu.previousElementSibling;
                    closeSubmenu(visibleSubmenu, visibleSubmenuToggle);
                }
                // then open the new one
                openSubmenu(closestSubmenu, clickTarget);
            } else if (closestSubmenu.classList.contains('sub-menu-open')) {
                // if the submenu itself is open, close it
                closeSubmenu(closestSubmenu, clickTarget)
            } else {
                // do nothing
            }
        }

        // handles submenu open/close on key events (desktop)
        function handleSubmenuToggleKeys(event) {
            const keyTarget = event.target;
            const closestSubmenu = keyTarget.nextSibling;
            // allow submenu toggling when using enter key or space bar
            if (event.key === 'Enter' || event.key === ' ') {
                // if the targeted submenu isn't already open
                if (!closestSubmenu.classList.contains('sub-menu-open')) {
                    const visibleSubmenu = document.querySelector('.sub-menu-open');
                    //if there is a submenu already open, close it
                    if (visibleSubmenu) {
                        const visibleSubmenuToggle = visibleSubmenu.previousElementSibling;
                        closeSubmenu(visibleSubmenu, visibleSubmenuToggle);
                    }
                    // then open the new one
                    openSubmenu(closestSubmenu, keyTarget);
                } else if (closestSubmenu.classList.contains('sub-menu-open')) {
                    // if the submenu itself is open, close it
                    closeSubmenu(closestSubmenu, keyTarget);
                }
            }

            // allow escape key to close submenu
            if (event.key === 'Escape') {
                if (closestSubmenu.classList.contains('sub-menu-open')) {
                    // if the submenu is already open, close it
                    closeSubmenu(closestSubmenu, keyTarget);
                }
            }
        }

        // toggles click listeners on mobile to key listeners on desktop
        function toggleKeyClickListeners(event) {
            if (event.matches) {
                // if is desktop width
                // attach the keys listener
                navMenu.addEventListener('keyup', handleSubmenuToggleKeys);
                // and remove the click listener
                navMenu.removeEventListener('click', handleSubmenuToggleClicks);
            } else {
                // if is mobile width
                // attach the click listener
                navMenu.addEventListener('click', handleSubmenuToggleClicks);
                // and remove the keys listener
                navMenu.removeEventListener('keyup', handleSubmenuToggleKeys);
            }
        }

        // handles toggling submenu click and key listeners when screen size changes
        isDesktopWidth.addEventListener('change', toggleKeyClickListeners);

        // also attach the proper listener on page load
        toggleKeyClickListeners(isDesktopWidth);

        // if focus moves from the main nav, close any open submenu
        function handleNavFocus(event) {
            const mainNav = event.currentTarget;
            // give browser time to focus the next element
            requestAnimationFrame(() => {
                // check if the new focused element is a child of the original container
                if (!mainNav.contains(document.activeElement)) {
                    const visibleSubmenu = document.querySelector('.sub-menu-open');
                    // if there is a submenu already open, close it
                    if (visibleSubmenu) {
                        const visibleSubmenuToggle = visibleSubmenu.previousElementSibling;
                        // close the open submenu
                        closeSubmenu(visibleSubmenu, visibleSubmenuToggle);
                    }
                }
            });
        }

        // handles closing an open submenu when focus leaves the main nav
        navMenu.addEventListener('focusout', handleNavFocus);

        function toggleSearchInput(isVisible, input) {
            const isMobileView = window.matchMedia('(max-width: 768px)').matches; // Check if viewport width is mobile

            // Early return if input is not defined
            if (!input) {
                return;
            }

            if (isVisible) {
                input.classList.add("slide-in-right", "display-show");
                input.classList.remove("slide-out-right", "display-none");
                if (isMobileView) input.classList.add("blur-background");
                input.focus();
            } else {
                input.classList.remove("slide-in-right", "blur-background", "display-show");
                input.classList.add("slide-out-right", "display-none");
            }
        }

        if (searchTriggers.length > 0 && searchContainer) {

            searchTriggers.forEach(trigger => {
                trigger.addEventListener('click', function() {

                    // turn off menu if it exists
                    // NOTE: Duplicate of mobilemenu
                    // closeMobileMenu(null, true)
                    if (nav.classList.contains('mobile-menu-open')) {
                        // close any open submenu
                        const visibleSubmenu = document.querySelector('.sub-menu-open');
                        if (visibleSubmenu) {
                            const visibleSubmenuToggle = visibleSubmenu.previousSibling;
                            closeSubmenu(visibleSubmenu, visibleSubmenuToggle);
                        }
                        // and close the mobile menu
                        toggleMobileMenu();
                    }

                    isSearchbarShowing = !isSearchbarShowing;
                    toggleSearchInput(isSearchbarShowing, searchContainer)
                });
            });
        }
    }

}
