const handleBackToTop = () => {
    const backToTopButtons = document.querySelectorAll('.scroll-to-top');

    if (!backToTopButtons.length) {
        return;
    }

    backToTopButtons.forEach(button => {
        button.addEventListener('click', () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        });
    });

};

export default handleBackToTop;
