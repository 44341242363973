const handleReportingIndexSort = () => {
    const table = document.getElementById("businessTable");
    const headers = document.querySelectorAll(".sortable");
    const defaultColumnIndex = 3;
    const defaultOrder = "desc";

    if (!table) {
        return
    }

    sortTable(table, defaultColumnIndex, defaultOrder);
    updateIcons(headers[defaultColumnIndex - 1], defaultOrder);


    headers.forEach(header => {
        header.addEventListener("click", function () {
            const columnIndex = this.dataset.column;
            let order = this.dataset.order;

            order = order === "asc" ? "desc" : "asc";
            this.dataset.order = order;

            updateIcons(this, order);

            headers.forEach(h => h.classList.remove("sorted-asc", "sorted-desc"));

            this.classList.add(order === "asc" ? "sorted-asc" : "sorted-desc");

            sortTable(table, columnIndex, order);
        });
    });

    function sortTable(table, column, order) {
        const tbody = table.querySelector("tbody");
        const rows = Array.from(tbody.querySelectorAll("tr"));

        rows.sort((rowA, rowB) => {
            let cellA = rowA.cells[column].textContent.trim();
            let cellB = rowB.cells[column].textContent.trim();

            cellA = parseFloat(cellA.replace('%', '')) || 0;
            cellB = parseFloat(cellB.replace('%', '')) || 0;

            return order === "asc" ? cellA - cellB : cellB - cellA;
        });

        tbody.innerHTML = "";
        rows.forEach(row => tbody.appendChild(row));
    }

    function updateIcons(header, order) {
        document.querySelectorAll(".sortable i").forEach(icon => {
            icon.classList.remove("fa-chevron-up", "fa-chevron-down");
            icon.classList.add("fa-chevron-down");
        });

        const icon = header.querySelector("i");

        if (order === "asc") {
            icon.classList.remove("fa-chevron-down");
            icon.classList.add("fa-chevron-up");
        } else {
            icon.classList.remove("fa-chevron-up");
            icon.classList.add("fa-chevron-down");
        }
    }
}

export default handleReportingIndexSort;
