
/**
 * Set the text element's value to the value passed via the `lp-state` URL parameter.
 * Used to affect page/ACF content.
 */
export default function() {
    const getUrlParam = (param) => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(param) || false;
    };

    const setStateFromUrlParam = () => {
        const state = getUrlParam('lp-state');

        if (!state) {
            return jQuery('.lp-state').text('Wyoming');
        }

        jQuery('.lp-state').text(state);
    };

    jQuery(document).ready(function($) {
        setStateFromUrlParam();
    });
}
