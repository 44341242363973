import autosize from 'autosize';

export default function() {
    //Do yo JAHVAHSCRIPT here.

    //mobile nav controller
    autosize($('textarea'));

    // If the window is resized, and the Map block is present, wait one second and reload the page
    // We only care if the width changes
    let currentWidth = $(window).innerWidth();
    const widthThreshold = 10; //The minimum difference in width in pixels before the page reloads

    // TODO: An way to reload ONLY the component (instead of reload the whole page)
    let resizeMap = debounce(function() {
        let widthDifference = Math.abs($(window).innerWidth() - currentWidth);

        if( $('#map').length && ( widthDifference > widthThreshold ) ) {
            location.reload();
        }
    }, 1000);

    window.addEventListener('resize', resizeMap);


    // Source: https://davidwalsh.name/javascript-debounce-function
    // Returns a function, that, as long as it continues to be invoked, will not
    // be triggered. The function will be called after it stops being called for
    // N milliseconds. If 'immediate` is passed, trigger the function on the
    // leading edge, instead of the trailing.
    function debounce(func, wait, immediate) {
        var timeout;
        return function() {
            var context = this, args = arguments;
            var later = function() {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    };
}