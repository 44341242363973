import '../vendor/us-map-plugin.js'

/**
 * Toggle mobile state list dropdown and rotate arrow icon.
 */
const handleMobileStateListDropdown = () => {
    let stateList = $('.see-all-states');

    // On Initial Setup
    hideShowStateList(stateList);

    stateList.on('click', function () {
        const SLIDE_DURATION = 250;

        $(this).next().slideToggle(SLIDE_DURATION);

        hideShowStateList(this);
    });

    function hideShowStateList(el) {
        if( $(el).hasClass("opened") ) {
            $(el).text("Hide States List").removeClass("opened");
        } else {
            $(el).text("Show States List").addClass("opened");
        }
    }
};

/**
 * Retrieve the page url value for the selected state on the map.
 *
 * @param {string} stateAbbrev The selected state's abbreviated name.
 * @returns {string} The url to the selected state's page.
 */
const getStatePageUrl = (stateAbbrev) => {
    let tableData = $('.state-link-table');

    /** Get the state's page link by its abbreviated name. */
    const stateLink = tableData.find('a').filter(function () {
        return $(this).attr('data-abbrev') === stateAbbrev;
    });

    /** The state's page link. */
    console.log(stateLink[0].href);
    return stateLink[0].href;
};

/**
 * Set the configuration options for the jQuery us-map plugin.
 */
const mapConfig = () => {
    const [stateMapConfig] = $('.state-map-config');
    let stateColor = '#4A90E2';
    let stateHoverColor = '#29466E';

    if (typeof stateMapConfig !== 'undefined') {
        if (stateMapConfig.getAttribute('data-state-color')) {
            stateColor = stateMapConfig.getAttribute('data-state-color');
        }

        if (stateMapConfig.getAttribute('data-state-hover-color')) {
            stateHoverColor = stateMapConfig.getAttribute('data-state-hover-color');
        }
    }

    jQuery('#map').usmap({
        click(_, state) {
            const stateAbbrev = state.name; // Get the state abbrev.
            const statePageUrl = getStatePageUrl(stateAbbrev);// Get the state page URLAppend the state abbrev. as a query parameter to the URL

            // Append the state abbrev. as a query parameter to the URL and encode it.
            const urlWithState = `${statePageUrl}?st=${encodeURIComponent(stateAbbrev)}`;
            // Store the state abbreviation in local storage
            localStorage.setItem('selectedState', stateAbbrev);

            window.location.href = getStatePageUrl(state.name);
            window.location.href = urlWithState;
        },

        labelBackingHoverStyles: {
            fill: '#29466E',
        },
        labelBackingStyles: {
            fill: '#4A90E2',
            stroke: '#fff',
            'stroke-width': 1.5,
        },
        mouseout() {
            $('.map-insert').addClass('hidden');
        },
        mouseover(_, state) {
            $('.map-insert').removeClass('hidden').text(state.name);
        },
        showLabels: false,
        stateHoverStyles: {
            fill: stateHoverColor,
            stroke: '#fff',
        },
        stateStyles: {
            fill: stateColor,
            stroke: '#fff',
            'stroke-width': 1.5,
        },
    });
};

export default {
    init() {
        $(() => {
            handleMobileStateListDropdown();
            mapConfig();
        });
    },
};
