const getStateImage = () => {
    const stateDivs = document.querySelectorAll(".state-selector-container");
    const baseUrl = window.location.origin;
    const templateUrl = `${baseUrl}/wp-content/themes/registered-agents-inc-2024`;

    stateDivs.forEach((div) => {
        const stateImage = div.querySelector(".state-icon");
        const selectedState = div.id;
        const formattedState = selectedState.toLowerCase().replace(/\s+/g, "");

        if (selectedState && stateImage) {
            stateImage.setAttribute('src', `${templateUrl}/state-images/${formattedState}.png`);
            stateImage.setAttribute('alt', `${selectedState}`);
        }
    });
};
export default getStateImage